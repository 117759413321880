// src/pages/CourseFeesDuration.js
import React from 'react';
import './CourseFeesDuration.css';

const CourseFeesDuration = () => {
  const courses = [
    { name: 'Python Full Stack + 2 Projects', fees: '30,000', duration: '130 Hours (3.5 Months)' },
    { name: 'Python+Automation/Django + Project', fees: '20,000', duration: '80 Hours (2.5 Months)' },
    { name: 'Java Full Stack + 2 Projects', fees: '35,000', duration: '130 Hours (3.5 Months)' },
    { name: 'Java (Core Java+Adv Java+Project)', fees: '20,000', duration: '100 Hours (2 Months)' },
    { name: 'Oracle SQL/PLSQL + Project', fees: '25,000', duration: '110 Hours (2.5 Months)' },
    { name: 'UI Developer(Front End) + Project', fees: '16,000', duration: '50 Hours (2 Months)' },
    { name: 'Software Testing(Java+Selenium+Manual)', fees: '16,000', duration: '80 Hours (2.5 Months)' },
    { name: 'Power BI + Project', fees: '12,000', duration: '30 Hours (1 Month)' },
    { name: 'Embedded Automotive Testing + Project', fees: '20,000', duration: '60 Hours (1.5 Months)' },
    { name: 'SAP FICO + Project', fees: '25,000', duration: '160 Hours (4.5 Months)' },
    { name: 'Tableau', fees: '12,000', duration: '40 Hours (1.5 Months)' },
    { name: 'Informatica + Project', fees: '20,000', duration: '70 Hours (2 Months)' },
    { name: 'DevOps+AWS(Basic) + Project', fees: '15,000', duration: '60 Hours (2 Months)' },
    { name: 'Other Courses', fees: 'Varies', duration: 'Varies' },
  ];

  return (
    <div className="course-fees-duration">
      <h2>Course Fees & Duration</h2>
      <table>
        <thead>
          <tr>
            <th>Course</th>
            <th>Fees</th>
            <th>Duration</th>
          </tr>
        </thead>
        <tbody>
          {courses.map((course, index) => (
            <tr key={index}>
              <td>{course.name}</td>
              <td>{course.fees}</td>
              <td>{course.duration}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CourseFeesDuration;
