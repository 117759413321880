// src/pages/CyberSecurity.js

import React from 'react';
import './CyberSecurity.css';

const CyberSecurity = () => {
  return (
    <div className="cyber-security">
      <h1>Cyber Security Course</h1>
      <p>
        Welcome to the Cyber Security course offered by Aravind Software Academy Softwares Training Institute. <br/>
        This course is designed to equip you with the essential knowledge and skills to protect digital systems, <br/>
        networks, and data from cyber threats.
      </p>

      <h2>Course Highlights:</h2>
      <ul>
        <li>Introduction to Cyber Security and its importance</li>
        <li>Common cyber threats and attack vectors</li>
        <li>Network security principles and practices</li>
        <li>Encryption and cryptography fundamentals</li>
        <li>Secure coding practices and principles</li>
        <li>Incident response and management</li>
        <li>Security policies and compliance frameworks</li>
        <li>Cloud security and virtualization</li>
        <li>Ethical hacking and penetration testing</li>
      </ul>

      <h2>Why Choose Our Cyber Security Course?</h2>
      <ul>
        <li>Expert-led training with experienced cybersecurity professionals</li>
        <li>Hands-on labs and real-world simulations</li>
        <li>Practical skills development with industry-standard tools</li>
        <li>Certification preparation and exam guidance</li>
        <li>Job placement assistance and career counseling</li>
        <li>Flexible learning options: online and offline classes</li>
      </ul>

      <h2>Who Should Enroll?</h2>
      <p>
        This course is suitable for:
      </p>
      <ul>
        <li>IT professionals and system administrators</li>
        <li>Software developers interested in secure coding practices</li>
        <li>Network engineers and cybersecurity enthusiasts</li>
        <li>Students pursuing careers in cybersecurity</li>
      </ul>

      <h2>Contact Us</h2>
      <p>
        For more information about the Cyber Security course, please contact us at 8884752389.
      </p>
    </div>
  );
};

export default CyberSecurity;
