import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Navbar from './components/Navbar';
//import ContactForm from './components/ContactForm';
import MainContent from './components/MainContent';
import AboutUs from './pages/AboutUs';
import TrainersProfile from './pages/TrainersProfile';
import AdditionalContent from './components/AdditionalContent';
import FooterOrangeLineOnly from './components/FooterOrangeLineOnly'; // Import FooterOrangeLineOnly component

import Footer from './components/Footer'; // Import Footer component
import WhatsAppButton from './components/WhatsAppButton';

import './App.css';

// Import page components
import ClassroomTraining from './pages/ClassroomTraining';
import OnlineTraining from './pages/OnlineTraining';
import InterviewGuidance from './pages/InterviewGuidance';
import DataAnalytics from './pages/DataAnalytics';
import MachineLearningAI from './pages/MachineLearningAI';
import ChatGPTAITools from './pages/ChatGPTAITools';
import CyberSecurity from './pages/CyberSecurity';
import DataEngineering from './pages/DataEngineering';
import Cloud from './pages/Cloud';
import HadoopSpark from './pages/HadoopSpark';
import Python from './pages/Python';
import Scala from './pages/Scala';
import CCPJava from './pages/CCPJava';
import AndroidDevelopment from './pages/AndroidDevelopment';
import WebsiteDevelopment from './pages/WebsiteDevelopment';
import MSOffice from './pages/MSOffice';
import CollegeProjects from './pages/CollegeProjects';
import RouteMap from './pages/RouteMap'; // Import RouteMap component
import CourseFeesDuration from './pages/CourseFeesDuration';
import Reviews from './pages/Reviews';

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Navbar />
        <div className="container">
          <Routes>
            <Route path="/" element={<MainContent />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/trainers" element={<TrainersProfile />} />
            <Route path="/additional-content" element={<AdditionalContent />} />
            <Route path="/route-map" element={<RouteMap />} /> {/* Add RouteMap route */}
            <Route path="/course-fees" element={<CourseFeesDuration />} />
            <Route path="/reviews" element={<Reviews />} />
            {/* Services Routes */}
            <Route path="/services/classroom-training" element={<ClassroomTraining />} />
            <Route path="/services/online-training" element={<OnlineTraining />} />
            <Route path="/services/interview-guidance" element={<InterviewGuidance />} />
            {/* Courses Routes */}
            <Route path="/courses/data-analytics" element={<DataAnalytics />} />
            <Route path="/courses/machine-learning-ai" element={<MachineLearningAI />} />
            <Route path="/courses/chatgpt-ai-tools" element={<ChatGPTAITools />} />
            <Route path="/courses/cyber-security" element={<CyberSecurity />} />
            <Route path="/courses/data-engineering" element={<DataEngineering />} />
            <Route path="/courses/cloud" element={<Cloud />} />
            <Route path="/courses/hadoop-spark" element={<HadoopSpark />} />
            <Route path="/courses/python" element={<Python />} />
            <Route path="/courses/scala" element={<Scala />} />
            <Route path="/courses/c-java" element={<CCPJava />} />
            <Route path="/courses/android-development" element={<AndroidDevelopment />} />
            <Route path="/courses/website-development" element={<WebsiteDevelopment />} />
            <Route path="/courses/ms-office" element={<MSOffice />} />
            <Route path="/courses/college-projects" element={<CollegeProjects />} />
          </Routes>

        </div>
        {/* Two Footer Components */}
        <FooterOrangeLineOnly /> {/* Footer with Orange Line Only */}
        <Footer /> {/* Default Footer */}
        <WhatsAppButton />
      </div>
    </Router>
  );
}

export default App;
