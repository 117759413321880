import React, { useState, useEffect } from 'react';
import './MainContent.css';

// Import images from assets folder
import image1 from '../assets/image1.jpg';
import image2 from '../assets/image2.jpg';
import image3 from '../assets/image3.jpg';
import trainingProcessImage from '../assets/image4.jpg'; // Add your training process image here
import experiencedtrainers from '../assets/experiencedtrainers.png'
import afordablefees from '../assets/afordablefees.png'
import handsontraining from '../assets/handsontraining.png'
import interviewsupport from '../assets/interviewsupport.png'
import flexibletimings from '../assets/flexibletimings.png'
import decentenvironment from '../assets/decentenvironment.png'

const MainContent = () => {
  // State to manage current image index
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = [image1, image2, image3]; // Array of images

  // Function to move to the next image
  const nextImage = () => {
    setCurrentImageIndex((currentImageIndex + 1) % images.length);
  };

  // Automatic sliding functionality using useEffect and setInterval
  useEffect(() => {
    const interval = setInterval(() => {
      nextImage();
    }, 3000); // Change slide every 3 seconds (adjust as needed)

    return () => clearInterval(interval); // Clean up interval on component unmount
  }, [currentImageIndex]); // Re-run effect when currentImageIndex changes

  return (
    <div className="main-content">
      <div className="carousel-container">
        <img src={images[currentImageIndex]} alt="Training" className="main-image" />
      </div>
      
      <div className="intro-text">
        <h2>BEST SOFTWARE TRAINING INSTITUTE IN BANGALORE</h2>
        <h3>ONLINE & OFFLINE CLASSES</h3>
        <h3>REALTIME TRAINING & PROJECT</h3>
      </div>

      <div className="course-details">
        <h2>COURSE DETAILS</h2>
        <div className="details">
          <div className="detail-item">
            <img src={experiencedtrainers} alt="Experienced Trainers" />
            <h3>Experienced Trainers</h3>
            <p>All the trainers are well experienced, certified and working professionals.</p>
          </div>
          <div className="detail-item">
            <img src={afordablefees} alt="Affordable Fees" />
            <h3>Affordable Fees</h3>
            <p>Course fees is comparable to any other quality training institutes in Bangalore.</p>
          </div>
          <div className="detail-item">
            <img src={handsontraining} alt="Hands on Training" />
            <h3>Hands on Training</h3>
            <p>We are very sure that our training helps you to directly work in projects by giving hands on training.</p>
          </div>
          <div className="detail-item">
            <img src={interviewsupport} alt="100% Interview Support" />
            <h3>100% Interview Support</h3>
            <p>You can approach any time to trainer for any course related assistance.</p>
          </div>
          <div className="detail-item">
            <img src={flexibletimings} alt="Flexible Timings" />
            <h3>Flexible Timings</h3>
            <p>You have flexible timings for all the courses.</p>
          </div>
          <div className="detail-item">
            <img src={decentenvironment} alt="Decent Environment" />
            <h3>Decent Environment</h3>
            <p>Institute is located in very calm and greenery environment.</p>
          </div>
        </div>
      </div>

      <div className="training-process">
        <h2>TRAINING PROCESS</h2>
        <img src={trainingProcessImage} alt="Training Process" />
      </div>

      <div className="course-list">
        <h2>COURSE LIST</h2>
        <div className="course-column">
          <ul>
            <li>Data Analytics</li>
            <li>Machine learning & AI</li>
            <li>Chatgpt & AI Tools</li>
            <li>Cyber Security</li>
            <li>Data engineering</li>
          </ul>
        </div>
        <div className="course-column">
          <ul>
            <li>Cloud-AWS,Azure,GCP</li>
            <li>Hadoop & Spark</li>
            <li>Python</li>
            <li>Scala</li>
            <li>c,c++,Java</li>
          </ul>
        </div>
        <div className="course-column">
          <ul>
            <li>Android development</li>
            <li>Website development</li>
            <li>MS office </li>
            <li>College Projects</li>
          </ul>
        </div>
      </div>

      
    </div>
  );
};

export default MainContent;
