// src/pages/RouteMap.js

import React from 'react';
import './RouteMap.css'; // Create this CSS file for styling

const RouteMap = () => {
  return (
    <div className="route-map-container">
      <h2>Our Location</h2>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3889.489701932571!2d77.59431157064463!3d12.876201989207862!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae6bb561eff57d%3A0x1ce859ad53fd2b71!2sRAK%20Analytics%20Solutions%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1720095462600!5m2!1sen!2sin"
        width="600"
        height="450"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
      ></iframe>
      <h2>Contact Details</h2>
      <p><strong>Address:</strong> Bannerghatta Rd, Opp to BGS National School, Muthurayya Swamy Layout, Hulimavu.</p>
      <p><strong>Phone:</strong> +91 9900173022 (WhatsApp Message/Call)</p>
      <p><strong>Email:</strong> aravindtech.bglr@gmail.com</p>
    </div>
  );
};

export default RouteMap;
