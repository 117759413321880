// src/pages/Reviews.js
import React, { useEffect } from 'react';
import './Reviews.css';

const Reviews = () => {
  useEffect(() => {
    window.open('https://www.google.com/search?q=rak+analytic+solutions+pvt+ltd&oq=&gs_lcrp=EgZjaHJvbWUqCQgAECMYJxjqAjIJCAAQIxgnGOoCMgkIARAjGCcY6gIyCQgCECMYJxjqAjIJCAMQIxgnGOoCMgkIBBAjGCcY6gIyCQgFECMYJxjqAjIJCAYQIxgnGOoCMgkIBxAjGCcY6gLSAQ4xMDU3NDMwODc2ajBqN6gCCLACAQ&sourceid=chrome&ie=UTF-8&lqi=Ch5yYWsgYW5hbHl0aWMgc29sdXRpb25zIHB2dCBsdGRaLiIecmFrIGFuYWx5dGljIHNvbHV0aW9ucyBwdnQgbHRkKgwIAhAAEAEQAhADEASSARBzb2Z0d2FyZV9jb21wYW55qgFHEAEyHxABIhvtdNRXtuz9dazuaTB8N2ansh1DbezlRVCITVMyIhACIh5yYWsgYW5hbHl0aWMgc29sdXRpb25zIHB2dCBsdGQ#rlimm=2083013428632169329', '_blank');
  }, []);

  return (
    <div className="reviews-container">
      <h2 className="reviews-message">Redirecting to Google Reviews...</h2>
    </div>
  );
};

export default Reviews;
