// src/components/WhatsAppButton.js
import React from 'react';
import './WhatsAppButton.css';
import whatsappLogo from '../assets/whatsapp-logo.png'; // Adjust the path as necessary

const WhatsAppButton = () => {
  const phoneNumber = '+917022444169'; // Replace with your WhatsApp number in international format

  const handleClick = () => {
    const url = `https://wa.me/${encodeURIComponent(phoneNumber)}`;
    window.open(url, '_blank');
  };

  return (
    <div className="whatsapp-button" onClick={handleClick}>
      <img src={whatsappLogo} alt="WhatsApp" className="whatsapp-logo" />
      <span className="tooltip">WhatsApp</span>
    </div>
  );
};

export default WhatsAppButton;
