import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="navbar">
      <div className="navbar-toggle" onClick={toggleMenu}>
        ☰
      </div>
      <ul className={`navbar-links ${isOpen ? 'active' : ''}`}>
        <li><Link to="/" onClick={toggleMenu}>Home</Link></li>
        <li><Link to="/about-us" onClick={toggleMenu}>About Us</Link></li>
        <li className="dropdown">
          <Link to="#" onClick={toggleMenu}>Services</Link>
          <div className="dropdown-content">
            <Link to="/services/classroom-training" onClick={toggleMenu}>Classroom Training</Link>
            <Link to="/services/online-training" onClick={toggleMenu}>Online Training</Link>
            <Link to="/services/interview-guidance" onClick={toggleMenu}>Interview Guidance & Support</Link>
          </div>
        </li>
        <li className="dropdown">
          <Link to="#" onClick={toggleMenu}>Courses</Link>
          <div className="dropdown-content">
            <Link to="/courses/data-analytics" onClick={toggleMenu}>Data Analytics</Link>
            <Link to="/courses/machine-learning-ai" onClick={toggleMenu}>Machine Learning & AI</Link>
            <Link to="/courses/chatgpt-ai-tools" onClick={toggleMenu}>ChatGPT & AI Tools</Link>
            <Link to="/courses/cyber-security" onClick={toggleMenu}>Cyber Security</Link>
            <Link to="/courses/data-engineering" onClick={toggleMenu}>Data Engineering</Link>
            <Link to="/courses/cloud" onClick={toggleMenu}>Cloud - AWS, Azure, GCP</Link>
            <Link to="/courses/hadoop-spark" onClick={toggleMenu}>Hadoop & Spark</Link>
            <Link to="/courses/python" onClick={toggleMenu}>Python</Link>
            <Link to="/courses/scala" onClick={toggleMenu}>Scala</Link>
            <Link to="/courses/c-java" onClick={toggleMenu}>C, C++, Java</Link>
            <Link to="/courses/android-development" onClick={toggleMenu}>Android Development</Link>
            <Link to="/courses/website-development" onClick={toggleMenu}>Website Development</Link>
            <Link to="/courses/ms-office" onClick={toggleMenu}>MS Office</Link>
            <Link to="/courses/college-projects" onClick={toggleMenu}>College Projects</Link>
          </div>
        </li>
        <li><Link to="/course-fees" onClick={toggleMenu}>Course Fees & Duration</Link></li>
        <li><Link to="/trainers" onClick={toggleMenu}>Trainers Profile</Link></li>
        <li><Link to="/reviews" onClick={toggleMenu}>Reviews</Link></li>
        <li><Link to="/route-map" onClick={toggleMenu}>Route Map</Link></li>
      </ul>
    </nav>
  );
};

export default Navbar;