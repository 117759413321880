// src/pages/MachineLearningAI.js

import React from 'react';
import './MachineLearningAI.css';

const MachineLearningAI = () => {
  return (
    <div className="machine-learning-ai">
      <h1>Machine Learning & AI Course</h1>
      <p>
        Aravind Software Academy Softwares Training Institute offers a comprehensive Machine Learning & AI <br/>
        course designed to equip you with the skills and knowledge required to excel in the field of artificial <br/>
        intelligence and machine learning. Our course covers essential concepts, practical applications, and <br/>
        hands-on projects to prepare you for real-world challenges.
      </p>

      <h2>Course Highlights:</h2>
      <ul>
        <li>Introduction to Machine Learning & AI</li>
        <li>Fundamentals of Supervised and Unsupervised Learning</li>
        <li>Deep Learning and Neural Networks</li>
        <li>Natural Language Processing (NLP)</li>
        <li>Computer Vision Applications</li>
        <li>Reinforcement Learning Basics</li>
        <li>Model Evaluation and Performance Metrics</li>
        <li>Hands-on Projects with TensorFlow or PyTorch</li>
        <li>Deployment of Machine Learning Models</li>
      </ul>

      <h2>Why Choose Our Machine Learning & AI Course?</h2>
      <ul>
        <li>Expert Instructors with Industry Experience</li>
        <li>Practical Learning with Real-World Examples</li>
        <li>Customizable Learning Paths</li>
        <li>Access to Industry-Relevant Tools and Technologies</li>
        <li>Job Placement Support and Career Guidance</li>
        <li>Flexible Learning Options: Online and Offline Classes</li>
      </ul>

      <h2>Who Should Enroll?</h2>
      <p>
        This course is suitable for:
      </p>
      <ul>
        <li>Aspiring Data Scientists and Machine Learning Engineers</li>
        <li>Software Developers interested in AI applications</li>
        <li>Professionals seeking to enhance their AI skills</li>
        <li>Students pursuing careers in artificial intelligence and machine learning</li>
      </ul>

      <h2>Contact Us</h2>
      <p>
        For more information about the Machine Learning & AI course, please contact us at 8884752389.
      </p>
    </div>
  );
};

export default MachineLearningAI;
