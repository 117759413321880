import React from 'react';
import logo from '../assets/logo.png';
import './Header.css';

const Header = () => {
  return (
    <header className="header">
      <img src={logo} alt="Logo" className="logo" />
      <div className="contact-info1">
        <p>Bannerghatta Rd ,Opp to BGS National School, 
          <br/>Muthurayya Swamy Layout, Hulimavu,
            Bengaluru,Karnataka-560076</p>
        <p>+91-8884752389 | +91-7975191173 | +91-7975434331</p>
        <p>WhatsApp Message</p>
      </div>
    </header>
  );
};

export default Header;
