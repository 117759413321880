import React from 'react';
import './CollegeProjects.css'; // Import your CSS file

const CollegeProjects = () => {
  return (
    <div className="college-projects"> {/* Apply the CSS class to the wrapping div */}
      <h1>College Projects Course</h1>
      <p>
        Welcome to the College Projects course offered by Aravind Software Academy Softwares Training Institute. <br/> 
        This course is designed to guide college students through the process of conceptualizing, planning, <br/> 
        developing, and presenting innovative projects across various domains.
      </p>

      <h2>Course Highlights:</h2>
      <ul>
        <li>Understanding project requirements and objectives</li>
        <li>Project planning and proposal writing</li>
        <li>Research methodologies and literature review</li>
        <li>Software development lifecycle (SDLC) methodologies</li>
        <li>Choosing appropriate technologies and tools</li>
        <li>Designing user interfaces and user experience (UI/UX)</li>
        <li>Implementing project functionalities</li>
        <li>Testing, debugging, and quality assurance</li>
        <li>Documentation and report writing</li>
        <li>Preparing project presentations and demos</li>
      </ul>

      <h2>Why Choose Our College Projects Course?</h2>
      <ul>
        <li>Experienced instructors with project management expertise</li>
        <li>Hands-on project development and implementation</li>
        <li>Guidance on best practices and industry standards</li>
        <li>Preparation for project competitions and exhibitions</li>
        <li>Career guidance and preparation for future endeavors</li>
        <li>Flexible learning options: personalized mentoring and workshops</li>
      </ul>

      <h2>Who Should Enroll?</h2>
      <p>
        This course is suitable for:
      </p>
      <ul>
        <li>College students from various disciplines</li>
        <li>Students preparing for final year projects or thesis</li>
        <li>Those interested in developing practical skills in project management</li>
        <li>Individuals aiming to enhance their portfolio with innovative projects</li>
      </ul>

      <h2>Contact Us</h2>
      <p>
        For more information about the College Projects course, please contact us at 8884752389.
      </p>
    </div>
  );
};

export default CollegeProjects;
