import React from 'react';
import './AdditionalContent.css';

// Import image from assets folder
import image4 from '../assets/image4.jpg';

const AdditionalContent = () => {
  return (
    <div className="additional-content">
      <div className="section">
        <h2>BEST SOFTWARE TRAINING INSTITUTE IN BANGALORE || ONLINE & OFFLINE CLASSES</h2>
        <p>REALTIME TRAINING & PROJECT</p>
      </div>
      <div className="section">
        <h3>COURSE DETAILS</h3>
        <ul>
          <li><strong>Experienced Trainers:</strong> All the trainers are well experienced, certified, and working professionals.</li>
          <li><strong>Affordable Fees:</strong> Course fees are comparable to any other quality training institutes in Bangalore.</li>
          <li><strong>Hands-on Training:</strong> Our training includes hands-on projects to help you work directly on real-world applications.</li>
          <li><strong>100% Interview Support:</strong> You can approach trainers anytime for assistance related to course content.</li>
          <li><strong>Flexible Timings:</strong> We offer flexible timings for all courses to accommodate your schedule.</li>
          <li><strong>Decent Environment:</strong> Our institute is located in a calm and green environment.</li>
        </ul>
      </div>
      <div className="section">
        <img src={image4} alt="Institute Environment" className="institute-image" />
      </div>
      <div className="section">
        <h3>COURSE LIST</h3>
        <ul className="course-list">
          <li>Python Full Stack</li>
          <li>Java Full Stack</li>
          <li>UI/Front-End Developer</li>
          <li>Software Testing (Selenium)</li>
          <li>DevOps + AWS</li>
          <li>SAP FICO</li>
          <li>Oracle SQL/PLSQL</li>
          <li>Power BI</li>
          <li>Tableau</li>
          <li>Informatica</li>
          <li>Embedded Automotive Testing</li>
          <li>iOS Swift</li>
          <li>Data Science</li>
          <li>Network Protocol Dev</li>
          <li>Snowflake</li>
        </ul>
      </div>
    </div>
  );
};

export default AdditionalContent;
