// src/pages/HadoopSpark.js

import React from 'react';
import './HadoopSpark.css';

const HadoopSpark = () => {
  return (
    <div className="hadoop-spark">
      <h1>Hadoop & Spark Course</h1>
      <p>
        Welcome to the Hadoop & Spark course offered by Aravind Software Academy Softwares Training Institute.<br/> 
        This course is designed to equip you with the skills and knowledge required to handle large-scale data<br/> 
        processing and analysis using Apache Hadoop and Apache Spark.
      </p>

      <h2>Course Highlights:</h2>
      <ul>
        <li>Introduction to Big Data and its challenges</li>
        <li>Overview of Apache Hadoop ecosystem components</li>
        <li>Setting up and configuring Hadoop cluster</li>
        <li>HDFS (Hadoop Distributed File System)</li>
        <li>MapReduce programming model</li>
        <li>Introduction to Apache Spark and its advantages</li>
        <li>Spark RDDs (Resilient Distributed Datasets)</li>
        <li>Spark SQL and DataFrames</li>
        <li>Spark Streaming and real-time data processing</li>
        <li>Integration with Hadoop and other Big Data tools</li>
      </ul>

      <h2>Why Choose Our Hadoop & Spark Course?</h2>
      <ul>
        <li>Expert-led training with hands-on labs and projects</li>
        <li>Real-world use cases and practical scenarios</li>
        <li>Preparation for Cloudera or Hortonworks certifications</li>
        <li>Career guidance and job placement support</li>
        <li>Access to Hadoop and Spark clusters for practical exercises</li>
        <li>Flexible learning options: online and offline classes</li>
      </ul>

      <h2>Who Should Enroll?</h2>
      <p>
        This course is suitable for:
      </p>
      <ul>
        <li>Data engineers and Big Data professionals</li>
        <li>Software developers interested in distributed computing</li>
        <li>IT professionals seeking to advance their skills in Big Data technologies</li>
        <li>Students pursuing careers in data science and analytics</li>
      </ul>

      <h2>Contact Us</h2>
      <p>
        For more information about the Hadoop & Spark course, please contact us at 8884752389.
      </p>
    </div>
  );
};

export default HadoopSpark;
