import React from 'react';
import './FooterOrangeLineOnly.css'; // Import CSS for FooterOrangeLineOnly

const FooterOrangeLineOnly = () => {
  return (
    <footer className="footer1">
      <div className="orange-line"></div>
      <div className="contact-info">
        <p className="red-text">Please Call/WhatsApp @8884752389</p>
        <p className="blue-text">[ For updated content, demo video & batch Schedules ]</p>
      </div>
      <div className="orange-line"></div>
    </footer>
  );
}

export default FooterOrangeLineOnly;
