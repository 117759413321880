// src/pages/TrainersProfile.js

import React from 'react';
import './TrainersProfile.css';

const TrainersProfile = () => {
  return (
    <div className="trainers-profile">
      <h2>TRAINERS PROFILE</h2>

      <div className="trainer">
        <h3>Data Analytics – Vamsi (12+ Year IT Exp)</h3>
        <ul>
          <li>Over 10 years of training experience as Data Analytics  Developer</li>
          <li>Trained & Mentored Thousands of aspiring freshers and working professionals</li>
        </ul>
      </div>

      <div className="trainer">
        <h3>Machine learning & AI – Vinoth kumar (10+ Year IT Exp)</h3>
        <ul>
          <li>Over 10 years of training experience as Machine learning & AI developer</li>
          <li>Trained & Mentored Hundreds of aspiring freshers and working professionals</li>
        </ul>
      </div>

      <div className="trainer">
        <h3>Data Engineering – Karthi (10+ Year IT Exp)</h3>
        <ul>
          <li>Over 10+ years of experience as Data Engineering consultant</li>
          <li>Trained & Mentored Hundreds of aspiring freshers and working professionals</li>
        </ul>
      </div>

      <div className="trainer">
        <h3>Hadoop & Spark – Nishanth(12+ Year IT Exp)</h3>
        <ul>
          <li>Over 10+ years of training experience as Hadoop & Spark Developer</li>
          <li>Trained & Mentored Hundreds of aspiring freshers and working professionals</li>
        </ul>
      </div>

      <div className="trainer">
        <h3>Python – Murugan (12+ Year IT Exp)</h3>
        <ul>
          <li>He has 12 years of work experience in Perl, Data Science, Machine Learning, Python Scripting, <br/> 
            Data Management, Distributed Computing, Big Data, and Data Analytics</li>
          <li>Trained more than 400 students with his support and referral</li>
        </ul>
      </div>

      <div className="trainer">
        <h3>C,C++,Java – Kannan (18+ Year IT Exp)</h3>
        <ul>
          <li>Over 18 years of experience on IT in C,C++,Java</li>
          <li>7 Years of experience in professional training conducted for Classroom and Corporate training</li>
          <li>Excellent presentation, written and oral communication skills</li>
          <li>Trained More than 500 students for C,C++,Java course</li>
        </ul>
      </div>
    </div>
  );
};

export default TrainersProfile;
