// src/pages/OnlineTraining.js

import React from 'react';
import './OnlineTraining.css';

const OnlineTraining = () => {
  return (
    <div className="online-training">
      <h1>Online Training</h1>
      <p>
        Aravind Software Academy Softwares Training Institute provides the required material for reference and hands-on exercises <br/>
        for practical exposure. The students are monitored for any clarifications and doubts by the faculty throughout the course <br/>
        either by phone or mail as per the convenience of the participants.
      </p>
      <p>
        Our sole maxim in introducing Online Technical Training Sessions is to cater to the training requirements of the aspirants<br/> 
        who,due to various reasons, cannot travel physically to our location. In the online training program, the training sessions <br/>
        are conducted over the internet using the latest state-of-the-art software tools, and the hands-on experience will also be <br/>
        provided at the convenience of the participant.
      </p>

      <h2>Course Highlights:</h2>
      <ul>
        <li>Demo & First 2 free classes</li>
        <li>Classroom & Online</li>
        <li>Real Time training & Project</li>
        <li>Assignment and Case Studies</li>
        <li>Resume Building & Interview Guidance</li>
        <li>Mock Interviews</li>
        <li>100% Job Assistance (Freshers & Experienced)</li>
        <li>10+ Years of experienced & Working Trainers</li>
      </ul>
    </div>
  );
};

export default OnlineTraining;
