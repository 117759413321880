// src/pages/InterviewGuidance.js

import React from 'react';
import './InterviewGuidance.css';

const InterviewGuidance = () => {
  return (
    <div className="interview-guidance">
      <h1>Interview Support & Job Support</h1>
      <p>
        Aravind Software Academy Softwares Training Institute provides the best suggestions to students to <br />
        clear interviews and current market openings. We will try to help in resume updates and project explanations.
      </p>
      <p>
        <strong>FOR MORE DETAILS ON INTERVIEW SUPPORT CONTACT US @ 8884752389</strong>
      </p>
    </div>
  );
};

export default InterviewGuidance;
